@keyframes slideInFromRight {
    from {
        opacity: 0;
        left: 10%;
    }
    to {
        opacity: 1;
        left: 0;
    }
}

@keyframes slideInFromLeft {
    from {
        opacity: 0;
        left: -10%;
    }
    to {
        opacity: 1;
        left: 0;
    }
}

@keyframes fadeIn {
    from {opacity:0;}
    to {opacity:1;}
}
