.container {
    height: 100%;
}

@media (max-width: 768px) {
    .container {
        padding-top: 20px;
    }
  }

  
.react-multiple-carousel__arrow, .react-multiple-carousel__arrow:active, .react-multiple-carousel__arrow:hover{
    background: transparent;
}

.react-multiple-carousel__arrow::before{
    color: black;
}