body {
  margin: auto;
  font-family: "Euclid Flex Medium", Arial, sans-serif;
	src: url('../src/Fonts/EuclidFlex-Medium.otf');
  src:url('../src/Fonts/EuclidFlex-Light.otf');
  src:url('../src/Fonts/EuclidFlex-Regular.otf');

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
  overflow-x:hidden

}
html{
  background-color: #dbecda;
  overflow-x:hidden;
}
#root{
  background-color: white;
}
code {
  margin: 0;
  font-family: "Euclid Flex Medium";
	src: url('../src/Fonts/EuclidFlex-Medium.otf');
  src:url('../src/Fonts/EuclidFlex-Light.otf');
  src:url('../src/Fonts/EuclidFlex-Regular.otf');
  font-size: 17px;

}
@font-face {
	font-family: "Euclid Flex Medium";
	src: url('../src/Fonts/EuclidFlex-Medium.otf');
  src:url('../src/Fonts/EuclidFlex-Light.otf');
  src:url('../src/Fonts/EuclidFlex-Regular.otf');  

}